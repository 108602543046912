//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-816:_8256,_3512,_348,_9236,_4132,_9332,_6248,_500;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-816')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-816', "_8256,_3512,_348,_9236,_4132,_9332,_6248,_500");
        }
      }catch (ex) {
        console.error(ex);
      }