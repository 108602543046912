
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                return _createElement('li', { 'className': 'item' }, _createElement('div', { 'className': 'card-wrapper underline-links-hover' }, _createElement('a', {
                    'href': this.url,
                    'className': 'card card--standard card--media'
                }, _createElement('div', { 'className': 'card__inner gradient' }, _createElement('div', { 'className': 'card__media' }, _createElement('div', { 'className': 'media--transparent media--hover-effect' }, _createElement('img', {
                    'className': 'motion-reduce',
                    'data-sizes': 'auto',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                }))), _createElement('div', { 'className': 'card__badge top left' }, this.on_sale ? _createElement('span', {
                    'className': 'badge badge--bottom-left color-accent-1',
                    'key': '541'
                }, 'Sale') : null)), _createElement('div', { 'className': 'card__content' }, _createElement('div', { 'className': 'card__information' }, _createElement('h3', { 'className': 'card__heading h5' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'full-unstyled-link'
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'card-information' }, !this.out_of_stock ? _createElement('div', {
                    'className': 'badge badge-in-stock',
                    'key': '961'
                }, _createElement('svg', {
                    'width': '15',
                    'height': '15',
                    'aria-hidden': 'true'
                }, _createElement('circle', {
                    'cx': '7.5',
                    'cy': '7.5',
                    'r': '7.5',
                    'fill': 'rgb(62,214,96, 0.3)'
                }), _createElement('circle', {
                    'cx': '7.5',
                    'cy': '7.5',
                    'r': '5',
                    'stroke': 'rgb(255, 255, 255)',
                    'stroke-width': '1',
                    'fill': 'rgb(62,214,96)'
                })), _createElement('span', {}, 'In stock')) : null, this.out_of_stock ? _createElement('div', {
                    'className': 'badge badge-out-of-stock',
                    'key': '1369'
                }, _createElement('svg', {
                    'width': '15',
                    'height': '15',
                    'aria-hidden': 'true'
                }, _createElement('circle', {
                    'cx': '7.5',
                    'cy': '7.5',
                    'r': '7.5',
                    'fill': 'rgb(200,200,200, 0.3)'
                }), _createElement('circle', {
                    'cx': '7.5',
                    'cy': '7.5',
                    'r': '5',
                    'stroke': 'rgb(255, 255, 255)',
                    'stroke-width': '1',
                    'fill': 'rgb(200,200,200)'
                })), _createElement('span', {}, 'Out of stock')) : null, _createElement('div', { 'className': 'price' + (this.on_sale ? ' price--on-sale' : '') }, _createElement('div', { 'className': 'price__container' }, !this.on_sale ? _createElement('div', {
                    'className': 'price__regular',
                    'key': '1928'
                }, this.price_varies ? _createElement('span', { 'key': '1997' }, 'From: ') : null, _createElement('span', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.price)), this.set_qty && !this.price_varies ? _createElement('div', {
                    'className': 'price-item price-item--regular price-item--set',
                    'key': '2162'
                }, '\n                    Set of ', this.set_qty, ': ', this.formatPrice(this.price * this.set_qty), '\n                  ') : null) : null, this.on_sale ? _createElement('div', {
                    'className': 'price__sale',
                    'key': '2427'
                }, _createElement('span', { 'className': 'price-item price-item--last price-item--sale' }, '\n                    ', this.formatPrice(this.price), '\n                  '), _createElement('span', { 'className': 'price-item price-item--regular' }, '\n                     ', _createElement('del', {}, this.formatPrice(this.compare_at_price))), this.set_qty && !this.price_varies ? _createElement('div', {
                    'className': 'price-item price-item--last price-item--sale price-item--set',
                    'key': '2820'
                }, '\n                    Set of ', this.set_qty, ': ', this.formatPrice(this.price * this.set_qty), '\n                  ') : null) : null))), [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '31500'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI2.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null]), this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '3570'
                }) : null)), _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'id': 'product-form-installment-' + this.removeHTML(this.id),
                    'acceptCharset': 'UTF-8',
                    'className': 'installment caption-large',
                    'encType': 'multipart/form-data'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'form_type',
                    'value': 'product'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'utf8',
                    'value': '\u2713'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids[0]
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'product-id',
                    'value': this.removeHTML(this.id)
                }), _createElement('shopify-payment-terms', {
                    'variant-id': this.variant_ids[0],
                    'shopify-meta': window.Convermax.calcPaymentTerms(this)
                }))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'grid cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []