import shopifyDawnGenericDefaults from '../shopify-dawn-generic/config.js';

export default {
  includes: ['shopify-dawn-generic'],
  ...shopifyDawnGenericDefaults,
};

/* eslint-disable camelcase */
globalThis.Convermax.calcPaymentTerms = (item) => {
  const terms = {
    type: 'product',
    variants: [
      {
        id: item.variant_ids[0],
        price_per_term: item.formatPrice(item.price / 4),
        full_price: item.formatPrice(item.price),
        eligible: true,
        available: item.stock.includes('Available'),
        number_of_payment_terms: 4,
      },
    ],
    min_price: '$50.00',
    max_price: '$30,000.00',
    financing_plans: [
      {
        min_price: '$50.00',
        max_price: '$149.99',
        terms: [
          {
            apr: 0,
            loan_type: 'split_pay',
            installments_count: 4,
          },
        ],
      },
      {
        min_price: '$150.00',
        max_price: '$999.99',
        terms: [
          {
            apr: 0,
            loan_type: 'split_pay',
            installments_count: 4,
          },
          {
            apr: 15,
            loan_type: 'interest',
            installments_count: 3,
          },
          {
            apr: 15,
            loan_type: 'interest',
            installments_count: 6,
          },
          {
            apr: 15,
            loan_type: 'interest',
            installments_count: 12,
          },
        ],
      },
      {
        min_price: '$1,000.00',
        max_price: '$30,000.00',
        terms: [
          {
            apr: 15,
            loan_type: 'interest',
            installments_count: 3,
          },
          {
            apr: 15,
            loan_type: 'interest',
            installments_count: 6,
          },
          {
            apr: 15,
            loan_type: 'interest',
            installments_count: 12,
          },
        ],
      },
    ],
    installments_buyer_prequalification_enabled: true,
    seller_id: 1808887,
  };

  return JSON.stringify(terms);
};
/* eslint-enable camelcase */
